<template>
  <div
    class="baseButton c-pointer select-none !dark:text-white"
    :class="[
      {
        [$props.color]: $props.color,
        'baseButton--round': $props.round,
        'baseButton--large': $props.large,
        'baseButton--small': $props.small,
        'baseButton--navbar': $props.navbar,
        [`elevation-${$props.elevation}`]: $props.elevation,
      }]"
  >
    <slot />
  </div>
</template>

<script>
/**
 * @group Base
 * Functional component, renders a button, content is set to display: inline-flex
 */
export default {
  props: {
    // Increases height to 6rem
    large: {
      type: Boolean,
      default: false,
    },
    // Increases height to 6rem
    small: {
      type: Boolean,
      default: false,
    },
    // Set the box shadow
    elevation: {
      type: [String, Number],
      default: 0,
      validator (value) {
        if (value === 0) {
          return true
        }
        // The value must match one of these strings or be empty
        const sizes = ['elevation-1', 'elevation-2', 'elevation-3']
        return sizes.includes(`elevation-${value}`)
      },
    },
    // Set the background colour of the button
    color: {
      type: String,
      default: '',
    },
    // Sets a border radius of 48px
    round: {
      type: Boolean,
      default: false,
    },
    // Sets special styling for navbar
    navbar: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.baseButton {
  height: 48px;
  padding-right: 24px;
  padding-left: 24px;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 8px;
  color: $font-color-primary;
  border: $button-border;
  font-weight: 500;
}

.baseButton--small {
  height: 38px;
  padding: 0 25px 0 25px;
}

.baseButton--large {
  height: 60px;
  padding: 0 25px 0 25px;
}

.baseButton--round {
  border-radius: 48px;
}

.primary .baseButton--navbar {
  color: $color-white;
}

.baseButton--navbar {
  color: $color-grey-porpoise--light;
  padding: 16px;
  width: 100%;
  border: none;
  justify-content: flex-start;
}
</style>
